import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 19일 과학기술정보통신부에 5G 28㎓ 신규사업자 주파수 할당 신청 접수 완료… 경쟁력있는 컨소시엄 구성 및 협력을 통한 성공모델 제시",
  "| B2B, B2C 아우르는 리얼 5G 혁신서비스 제공을 위한 핫스팟 구축 예정",
  "| 통신망뿐 아니라 단말까지 인프라 투자 확대하여 수준높은 5G 경험 제공과 동시에 가계통신비까지 절감할 수 있는 방안 마련… ‘국민들께 이익 갈 수 있도록 노력하는 기업 될 것’",
]

const content = () => (
  <div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 주요 기업들과의 컨소시엄을 통해 신규법인 ‘스테이지엑스’(가칭, 이하 ‘스테이지엑스’)를 설립하고,
      과학기술정보통신부에 ‘5G 28㎓ 신규사업자 전국단위 주파수 할당 신청’을 완료하였다고 19일 밝혔다. 대한민국 중요 국책사업인
      ‘5G망 활성화’에 기여하고 새로운 통신 생태계를 만들어 나가는 데에 도전장을 내밀게 된 셈이다.
      <br />
      <br />
      스테이지엑스는 이번 신청을 통해 △국내 통신시장 경쟁활성화 △가계통신비 절감 △5G 28㎓ 기반 혁신 생태계 구축이라는 3대 목표를
      수립하고, 국가차원의 5G 기술을 선도하는 기업으로 자리매김할 예정이다.
      <br />
      <br />
      구체적으로, 스테이지엑스는 신규 사업자로서 국내 통신시장 경쟁활성화의 포문을 열 계획이다. 서비스, 요금, 품질 등 다각적인
      방면에서의 경쟁 촉진을 통해 가계통신비 부담 완화 및 국민 편익 제고에 기여하는 것이 목표다. 단말기 구입 비용에 대한 부담
      경감도 놓칠 수 없는 부분이다. 이를 위해 5G 28㎓대역을 포함한 중저가 단말의 자체 라인업 확대도 준비 중이며, 폭스콘 내 모바일
      디바이스 제조관련 계열사와의 전략적 제휴를 체결하였다.
      <br />
      <br />
      스테이지엑스는 향후 3년간 총 90개의 핫스팟에 6,000여개 이상의 무선기지국을 구축하여 B2B 및 B2C 모두를 대상으로 ‘Real 5G 혁신
      서비스’를 선보일 예정이다. 해당 서비스는 28㎓대역이 가진 주파수 특성을 고려하여 대학교, 병원, 경기장, 공연장, 공항 등의
      유형별 선도기업 및 단체와의 구축을 우선으로 하며, 충분한 실증 후 확산하는 형태로 진행한다. 실증기간 내 해당 주파수 대역 혁신
      서비스 모델 발굴 및 상용화를 통해 성공사례를 창출하고, 국내 5G 28㎓ 생태계를 활성화한다는 복안이다. 궁극적으로는 국가 차원의
      미래 ICT 분야 경쟁력 확보에 기여하고, 나아가 6G 시장 선도를 위한 기술 개발과 사업 인프라 및 생태계를 구축하는 것을 목표로
      한다.
      <br />
      <br />
      이를 위해 자체 컨소시엄도 각 분야를 리딩하고 있는 IT기반 기업들과 함께 하며 탄탄하게 구성했다. 간편통신플랫폼 ‘핀다이렉트’를
      통해 혁신 MVNO 및 로밍 서비스를 선보여 왔으며, 세그먼트향 스마트폰 및 태블릿 사업을 통해 디바이스에 대한 경험을 축적해온
      통신에 진심인 기업 ‘스테이지파이브’를 주축으로, 실제 사업 수행을 위한 각 분야별 선도기업 및 단체는 물론, 자금력을 갖춘
      재무적 투자자인 신한투자증권도 함께 했다. 신한투자증권은 지난 21년 9월 스테이지파이브의 투자자로 합류하고, 포괄적
      동반성장업무협약을 체결한 이래 지속적으로 금융주관 및 자문 역할을 수행하고 있다.
      <br />
      <br />
      우선적으로, 스테이지엑스는 국내 최초의 연구중심 이공계 특수대학인 KAIST(과학기술원)의 ICT 연구센터와 함께 실증과정을 거친다.
      해외 유수의 대학-통신사 리빙랩 모델에서 착안하여, KAIST 내 국내 최초 28㎓대역이 적용된 리빙랩을 구축하고 이를 KAIST 내
      연구개발 환경에 적용할 수 있도록 확대해 나아갈 예정이다.
      <br />
      <br />
      연세의료원(세브란스)과도 함께 Real 5G 혁신 서비스 환경을 구현한다. 연세의료원은 해당 실증기간을 통해 5G 기반의 의료 IoT
      기기, 의료 영상, 로봇 등 다양한 혁신 서비스 구현과 진료∙물류∙AI 활용의 융합 서비스 확산으로 디지털 기반 스마트병원의
      대전환기를 맞이할 예정이다.
      <br />
      <br />
      스테이지엑스는 국내 주요 경기장 및 공연장과도 협업하여 K-콘텐츠에 적합한 통신사업모델을 개발 및 제공할 계획 중에 있다. 해당
      공간이 단순 관람을 위한 공간에 그치지 않고 다양한 실감형 컨텐츠를 즐기며 차별적 관람 경험을 할 수 있는 공간으로 탈바꿈할 수
      있도록 5G 28㎓ 대역을 통해 AR, VR 콘텐츠에 최적화된 통신환경을 구현하고 B2C로 서비스할 예정이다.
      <br />
      <br />
      마지막으로, 국제공항 내 5G 28㎓ 대역을 도입하여 내국인 뿐 아니라 대한민국에 입국하는 외국이용객들에게도 빠른 통신속도를
      체감케 하고, 이에 기반한 여러 서비스를 발굴하여 제공할 예정이다. 국제공항 이용객 수는 인천공항 기준 연간 1,790만명에 달한다.
      따라서 스테이지엑스의 국제공항 내 5G 28㎓ 대역 도입은 통신강국이라는 국가브랜드 이미지 강화에 이바지할 것으로 예견된다.
      <br />
      <br />
      스테이지엑스의 주축이 될 스테이지파이브는 테크기반 기간통신사업자이다. 2019년 규제샌드박스 사업을 통해 통신가입 과정에서
      사설 간편인증 (카카오페이인증)을 업계 최초로 도입하는 등, 통신∙IT분야에서의 혁신성을 꾸준히 증명해왔다. 최근에는 이통3사
      빌링시스템 구축, 네이버클라우드 및 해외 주요 통신사업자의 제휴 등을 통해 적극적인 인프라 투자 행보를 보이고 있으며,
      풀MVNO사업을 위해 코어망 구축에 대한 기술적 검토와 사업적 준비를 마친 바 있다. 코어망은 전국단위 통신 서비스를 위한 MNO와의
      로밍 협력 시 필수적인 설비이기도 하므로. 향후 스테이지엑스가 주파수를 할당받게 되면 그대로 적용 가능하다.
      <br />
    </p>
  </div>
)

const press45 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 12월 19일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 5G(28㎓) 신규사업자 주파수 할당 신청"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press45
